.login-container {
	background-color: whitesmoke;
	display: flex;
	width: 100vw;
	height: 100vh;
	justify-content: center;
}

.login-box {
	position: relative;
	top: 10rem;
	color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 456px;
	height: 500px;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.login-box::after {
	content: "";
	background-color: white;
	position: absolute;
	width: 456px;
	height: 443px;
	z-index: -1;
}

.login-form {
	display: flex;
	position: relative;
	gap: 30px;
	flex-direction: column;
}

.login-butom {
	margin-top: 2rem;
	padding: 10px;
	background-color: #ff7900;
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 4px;
	width: 380px;
	height: 40px;
	cursor: pointer;
}

.login-butom:hover {
	background-color: #00593A;
}

.login-label {
	display: flex;
	width: 380px;
	height: 40px;
	flex-direction: column;
	gap: 10px;
}

.login-input {
	display: flex;
	width: 380px;
	height: 40px;
	background-color: #f7f7f7;
	border: 1px solid transparent;
}

.new-acount {
	display: flex;
	justify-content: center;
	position: relative;
	top: 30%;
}

.login-text {
	color: #00593A;
	max-width: 40%;
	max-height: 50%;
	display: flex;
	align-items: baseline;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	left: 10%;
	top: 20%;
}

.text {
	color: #000;
	display: flex;
	text-align: justify;
}
