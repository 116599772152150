.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-box {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    padding: 3%;
    padding-top: 0%;
    padding-bottom: 40%;
    width: 1308px;
    height: 790px;
    border-radius: 5%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.form {
    display: flex;
    position: relative;
    gap: 4%;
}

.form-item {
    position: relative;
    top: 6rem;
}

.label-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: black;
    font-size: 20px;
    gap: 10px;
}

.label-form option {
    font-size: 16px;
    color: #4a4a4a;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: white;
}

.img-top {
    max-width: 100%;
    height: auto;
}
  
.label-form option:hover {
    background-color: #ccc;
}

select {
    -webkit-appearance: none; /* Remove o estilo padrão do select no Safari */
    appearance: none; /* Remove o estilo padrão do select */
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #333; /* Cor do texto */
    padding: 10px; /* Preenchimento interno */
    border: 1px solid #ccc; /* Borda do select */
    border-radius: 5px; /* Borda arredondada */
    background-color: #fff; /* Cor de fundo */
    width: 100%; /* Largura total do select */
}
  
  /* Estilo para ajustar a posição vertical da seta (ajuste conforme necessário) */
select::-ms-expand {
    top: 5px; /* Ajuste a posição vertical da seta */
}

select:focus {
    outline: none;
}

.label-container {
    display: flex;
    align-items: center;
}
  
.campos {
    padding: 10px;
    max-width: 100vw;
    width: 308px;
    height: 69px;
    font-size: 20px;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    color: black !important;
    font-weight: inherit !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align-last: auto;
    padding: 0 30px; /* Adjust as needed to center the option visually */
    border-radius: 20px !important;
}
  
.campo-mandamentos {
    padding: 10px;
    width: 40rem;
    padding-right: 25px;
    height: 69px;
    font-size: 20px;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    color: black !important;
    font-weight: inherit !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align-last: auto;
    padding: 0 30px; /* Adjust as needed to center the option visually */
    border-radius: 20px !important;
    max-width: 100vw;
}

.campo-nome {
    padding: 10px;
    max-width: 100vw;
    width: 446px;
    height: 64px;
    font-size: 20px;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    color: black !important;
    font-weight: inherit !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
  
.form-butom1[type="submit"] {
    margin-top: 15rem;
    padding: 10px;
    background-color: #ff7900;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    width: 206px;
    height: 40px;
}

.form-butom[type="submit"] {
    margin-top: 15rem;
    padding: 10px;
    background-color: #ff7900;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    width: 206px;
    height: 40px;
}

.form-butomVoltar {
    margin-top: 15rem;
    position: relative;
    padding: 10px;
    background-color: #45a049;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    width: 206px;
    height: 40px;
}
  
.form-butom[type="submit"]:hover {
    background-color: #45a049; /* Darker green */
}

.form-butomVoltar [type="submit"]:hover {
    background-color: #ff7900; /* Darker orange */
}

.container-respostas {
    display: flex;
    background-color: #f2f2f2;
    position: relative;
    bottom: -5rem;
    flex-direction: row;
    width: 1200px;
    height: 193px;
    justify-content: space-evenly;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
}
  
.respostas {
    display: flex;
    flex-direction: row;
    width: 84%;
    justify-content: space-evenly;
    align-items: center;
}

.font {
    display: flex;
    color: black;
    font-weight: bold;
}

.result {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
/* STEP 2 */
.info-box {
	display: flex;
	background-color: #f2f2f2;
	position: relative;
	width: 1200px;
	height: 90px;
	justify-content: space-evenly;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	border-radius: 30px;
}

.info {
	display: flex;
	width: 122%;
	align-items: center;
	padding: 10px;
	margin: 10px;
	flex-direction: row;
	gap: 5%;
	justify-content: space-evenly;
}

.coins {
	display: flex;
	justify-content: center;
	position: relative;
	top: 4rem;
	gap: 5%;
}

.buttom-coin {
	background-color: transparent;
	border-radius: 100%;
	border: 0px transparent;
}

.selected-coin {
	border: 14px solid #ff7900;
	border-radius: 50%;
}

.coin-button[type="submit"] {
    margin-top: 15rem;
    padding: 10px;
    background-color: #ff7900;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    width: 206px;
    height: 40px;
}

.coin-button {
    border-color: #ff7900;
}

.coin-buton[type="submit"]:hover {
    background-color: #45a049; /* Darker green */
}

.coin-selection-message {
    position: relative;
    top: 6rem;
}

.button-nextprevew {
    display: flex;
    justify-content: center;
    position: relative;
    top: 6rem;
    gap: 2%;
}
  
  /* STEP 3 */
  
.command-box {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    padding: 3%;
    padding-top: 0%;
    padding-bottom: 40%;
    width: 1308px;
    height: 200px;
    border-radius: 5%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.label-command {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-command {
    font-weight: bold;
    max-width: 100vw;
    display: flex;
    background-color: #f2f2f2;
    position: relative;
    top: -2rem;
    width: 1200px;
    height: 90px;
    justify-content: space-evenly;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
}

.input-command {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    position: relative;
    top: 2rem;
    padding: 10px;
    width: 914px;
    height: 208px;
    font-size: 20px;
    border: 1px solid transparent !important;
    border-radius: 34px !important;
    background-color: transparent !important;
    color: black !important;
    font-weight: inherit !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.input-text {
    display: flex;
    position: relative;
    width: 857px;
    height: 157px;
    top: 1.3rem;
}

.input-text::placeholder {
    text-align: center;
}

.command-button[type="submit"] {
    position: relative;
    top: -7rem;
    margin-top: 0rem;
    padding: 8px;
    background-color: #ff7900;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    width: 206px;
    height: 40px;
}

.form-command {
    text-align: center;
    position: relative;
    top: -1rem;
    padding: 10px;
    width: 914px;
    height: 80px;
    font-size: 20px;
    border: 1px solid transparent !important;
    border-radius: 34px !important;
    background-color: transparent !important;
    color: black !important;
    font-weight: inherit !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.form-command option {
    font-size: 16px;
    color: #4a4a4a;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: white;
    text-align: justify;
    text-align-last: justify;
}
  
.form-command option:hover {
    background-color: #ccc;
}
  
.description-span {
    display: flex;
    position: absolute;
    top: 16%;
    right: 62%;
}

.hidden {
    display: none;
}

.command-data {
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    top: 6rem;
    gap: 10%;
    padding: 10px;
    width: 404px;
    height: 64px;
    font-size: 20px;
    border: 1px solid transparent !important;
    border-radius: 34px !important;
    background-color: transparent !important;
    color: black !important;
    font-weight: inherit !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.form-date {
    width: 14rem;
    font-size: 23px;
    color: #4a4a4a;
    padding: 10px;
    border-radius: 5px;
    border: 1px transparent;
    background-color: transparent;
}

.form-date-info {
    display: flex;
    font-size: 16px;
    color: rgb(255, 110, 0);
    position: relative;
    left: 15%;
    align-items: center;
}
  
  /* STEP 4 */
  
.final-box {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    padding: 3%;
    padding-top: 0%;
    padding-bottom: 40%;
    width: 1308px;
    height: 200px;
    border-radius: 5%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.box-final {
    display: flex;
    background-color: #f2f2f2;
    position: relative;
    width: 1200px;
    height: 531px;
    justify-content: space-evenly;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
}

.info-final {
    display: flex;
    width: 122%;
    align-items: center;
    padding: 10px;
    margin: 10px;
    flex-direction: row;
    gap: 5%;
    justify-content: space-evenly;
    flex: 1;
    max-width: 50%;
    padding: 0 20px;
    max-width: 80vw;
}

.info-final img {
    max-width: 100%;
}

.colum1 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 30rem;
    line-height: 2;
}

.colum2 {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 30rem;
    line-height: 1.3;
}

.final-respostas {
    font-size: 20px;
    text-align: justify;
}

.final-back[type="submit"] {
    margin-top: 4rem;
    padding: 10px;
    background-color: #01583c;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    width: 206px;
    height: 40px;
}

.final-button[type="submit"] {
    margin-top: 4rem;
    padding: 10px;
    background-color: #ff7900;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    width: 206px;
    height: 40px;
}

.box-button {
    display: flex;
    justify-content: center;
    position: relative;
    gap: 1%;
}
  
.react-datepicker {
    border: none;
    box-shadow: none;
}
  
.react-datepicker__input-container {
    border: none;
    box-shadow: none;
}
  
.react-datepicker-wrapper {
    border: none;
    box-shadow: none;
}
  
.react-datepicker__input-container input:active,
.react-datepicker__input-container input:focus {
    border: none;
    outline: none;
}
  
.chevron {
    position: relative;
    right: 3rem;
}
  
.itens-form {
    display: block;
    position: relative;
}

.container-itens {
    position: relative;
    top: 6rem;
}

.container-title {
    display: flex;
    color: black;
    font-size: -webkit-xxx-large;
    font-weight: inherit;
}

.coin-container {
    display: flex;
    gap: 10px;
}

.coin-selection-message {
    font-size: 16px;
    margin: 10px 0;
    color: black;
  }
  @media (max-width: 2560px) {
    .img-top {
      max-width: 100%;
      height: auto;
    }
    .form-box {
      height: 340px;
    }
    .input-text {
      font-size: 19px;
    }
    .command-data {
      width: 430px;
    }
    .form-date-info {
      font-size: 19px;
    }
    .button-nextprevew {
      top: 0rem;
      height: 10%;
    }
  }
  @media (max-width: 1920px) {
    .img-top {
      max-width: 100%;
      height: auto;
    }
    .form-butom[type="submit"] {
      margin-top: 10rem;
    }
    .form-butomVoltar[type="submit"] {
      margin-top: 10rem;
    }
    .form-box {
      margin-top: 6%;
      height: 600px;
    }
    .coin-button[type="submit"] {
      margin-top: 9rem;
    }
    .coins {
      top: 4rem;
    }
    .command-box {
      height: 445px;
    }
    .command-data {
      width: 430px;
    }
    .form-date-info {
      font-size: 19px;
      display: flex;
      color: rgb(255, 110, 0);
      position: relative;
      left: 15%;
      align-items: center;
    }
    .input-text {
      font-size: 19px;
    }
    .final-box {
      height: 362px;
    }
  }
  @media (max-width: 1540px) {
    .img-top {
      max-width: 100%;
      height: auto;
    }
    .form-box {
      margin-top: 8%;
      height: 600px;
    }
    .coin-button[type="submit"] {
      margin-top: 2rem;
    }
    .coins {
      top: 4rem;
    }
    .command-box {
      height: 520px;
    }
    .final-box {
      height: 500px;
    }
  }
  @media (max-width: 1024px) {
    .form {
      flex-direction: column;
    }
    .form-item {
      top: 2rem;
    }
    .img-top {
      width: 50rem;
    }
    .form-box {
      margin-top: 14%;
      height: 1080px;
      width: 50rem;
    }
  
    .container-respostas {
      width: 40rem;
      height: 121px;
    }
    .chevron {
      position: relative;
      right: 3.7rem;
    }
    .itens-form {
      display: flex;
      position: relative;
      flex-direction: column;
    }
    .itens-mandamentos {
      left: 9%;
    }
    .label-container {
      width: 345%;
    }
    .info-command {
      width: 50rem;
      height: 6rem;
      left: 0.4rem;
    }
    .respostas {
      flex-direction: row;
      width: 40rem;
    }
    .label-form {
      width: 30%;
    }
    .campo-nome {
      width: 48.5rem;
    }
  
    .info {
      width: 34rem;
      flex-direction: row;
      gap: 0px;
    }
    .info-box {
      bottom: 2rem;
      width: 49rem;
      height: 6rem;
      left: 0.5rem;
    }
    .container-itens {
      top: 3rem;
    }
    .coin-button[type="submit"] {
      margin-top: 2rem;
    }
    .coin-container {
      flex-direction: column;
    }
    .coins {
      top: 10%;
    }
    .command-box {
      height: 520px;
    }
    .final-box {
      height: 500px;
    }
    .command-data {
      width: 318px;
      left: 12rem;
      height: 36px;
      top: 4rem;
    }
    .label-command {
      display: block;
      position: relative;
      right: 0;
      left: 2.5rem;
    }
    .input-command {
      width: 43rem;
      left: 0;
    }
    .form-date {
      width: 11rem;
    }
    .campos {
      left: 0rem;
      width: 50rem;
    }
    .campo-mandamentos {
      left: 0;
      width: 43.5rem;
    }
    .description-span {
      width: 20rem;
      left: 1rem;
    }
    .form-date-info {
      font-size: 15px;
      left: 6%;
    }
  
    .button-nextprevew {
      scale: 80%;
    }
    .box-final {
      width: 47rem;
      height: 40rem;
      left: 1.4rem;
    }
    .info-final {
      padding: 0 88px;
      flex-direction: column;
    }
    .colum1 {
      width: 28rem;
      left: 0rem;
      line-height: 2;
    }
    .colum2 {
      width: 28rem;
      left: 0rem;
    }
    .container-title {
      position: relative;
      left: 0;
    }
    .form-butom[type="submit"] {
      position: relative;
    }
    .form-butomVoltar[type="submit"] {
      margin-top: 10rem;
    }
  }
  @media (max-width: 780px) {
    .form {
      flex-direction: column;
    }
    .form-item {
      top: 2rem;
    }
    .img-top {
      width: 50rem;
    }
    .form-box {
      margin-top: 14%;
      height: 1080px;
    }
  
    .container-respostas {
      left: 1rem;
      width: 40rem;
      height: 121px;
    }
    .chevron {
      position: relative;
      right: 3.7rem;
    }
    .itens-form {
      display: flex;
      position: relative;
      flex-direction: column;
    }
    .itens-mandamentos {
      left: 9%;
    }
    .label-container {
      width: 345%;
    }
    .info-command {
      width: 43rem;
      height: 6rem;
      left: 0.4rem;
    }
    .respostas {
      flex-direction: row;
      width: 40rem;
    }
    .label-form {
      width: 30%;
    }
    .campo-nome {
      width: 41.5rem;
    }
    .info {
      width: 34rem;
      flex-direction: row;
      gap: 0px;
    }
    .info-box {
      bottom: 2rem;
      width: 43rem;
      height: 6rem;
      left: 0.5rem;
    }
    .container-itens {
      top: 1rem;
    }
    .coin-button[type="submit"] {
      margin-top: 2rem;
    }
    .coin-container {
      flex-direction: column;
    }
    .coins {
      top: 10%;
    }
    .command-box {
      height: 520px;
    }
    .final-box {
      height: 500px;
    }
    .command-data {
      width: 318px;
      left: 27%;
      height: 36px;
      top: 4rem;
    }
    .label-command {
      display: block;
      position: relative;
      right: 0;
    }
    .input-command {
      width: 43rem;
      left: 0;
    }
    .form-date {
      width: 11rem;
    }
    .campos {
      left: 0rem;
      width: 43rem;
    }
    .campo-mandamentos {
      left: 0;
      width: 43.5rem;
    }
    .description-span {
      width: 20rem;
      left: 1rem;
    }
    .form-date-info {
      font-size: 15px;
      left: 6%;
    }
  
    .button-nextprevew {
      left: -11rem;
      scale: 80%;
    }
    .box-final {
      width: 40rem;
      height: 40rem;
      left: 1.4rem;
    }
    .info-final {
      padding: 0 88px;
      flex-direction: column;
    }
    .colum1 {
      width: 28rem;
      left: 0rem;
      line-height: 2;
    }
    .colum2 {
      width: 28rem;
      left: 0rem;
    }
    .container-title {
      position: relative;
      left: 0;
    }
    .form-butom[type="submit"] {
      position: relative;
      left: 14.6rem;
    }
    .form-butomVoltar[type="submit"] {
      margin-top: 10rem;
      left: 14.6rem;
    }
  }
  @media (max-width: 440px) {
    .form {
      flex-direction: column;
    }
    .img-top {
      width: 23rem;
      position: relative;
      right: 0%;
    }
    .form-box {
      margin-top: 34%;
      height: 1080px;
      width: 23rem;
    }
  
    .container-respostas {
      left: 0;
      width: 344px;
      height: 150px;
    }
    .chevron {
      position: relative;
      right: 3.7rem;
    }
    /* .itens {
      left: 8%;
      max-width: 64%;
    } */
    .itens-mandamentos {
      left: 9%;
    }
    .label-container {
      width: 24rem;
    }
    .info-command {
      width: 370px;
      height: 210px;
      left: 0.4rem;
    }
    .respostas {
      flex-direction: column;
      width: 90%;
    }
    .label-form {
      width: 30%;
    }
    .campo-nome {
      width: 305%;
    }
    .info {
      width: 60%;
      flex-direction: column;
      gap: 0px;
    }
    .info-box {
      bottom: 2rem;
      width: 370px;
      height: 151px;
      left: 0.5rem;
    }
    .container-itens {
      top: 1rem;
      max-width: 100vw;
    }
    .coin-button[type="submit"] {
      margin-top: 2rem;
    }
    .coin-container {
      flex-direction: column;
    }
    .coins {
      top: 10%;
    }
    .command-box {
      height: 520px;
    }
    .final-box {
      height: 500px;
    }
    .command-data {
      width: 318px;
      left: 1rem;
      height: 36px;
    }
    .label-command {
      position: relative;
      right: 0;
      left: 0;
    }
    .input-command {
      width: 330px;
      left: 1rem;
    }
    .form-date {
      width: 11rem;
    }
    .campos {
      left: 0rem;
      width: 370px;
    }
    .campo-mandamentos {
      left: 0.5rem;
      width: 23rem;
      position: relative;
    }
  
    .description-span {
      width: 20rem;
      left: 1rem;
    }
    .form-date-info {
      font-size: 15px;
      left: 6%;
    }
  
    .button-nextprevew {
      scale: 80%;
      left: -12rem;
      top: -4rem;
    }
    .box-final {
      width: 331px;
      height: 710px;
      left: 2rem;
    }
    .info-final {
      max-width: 65%;
      padding: 0 88px;
      flex-direction: column;
    }
    .colum1 {
      width: 200%;
      left: 0rem;
      line-height: 2;
    }
    .colum2 {
      width: 171%;
      left: -1rem;
    }
    .container-title {
      position: relative;
      left: 1rem;
      max-width: 90vw;
    }
    .form-butomVoltar[type="submit"] {
      margin-top: 10rem;
    }
    .form-butom[type="submit"] {
      position: relative;
    }
    .coin-selection-message {
      top: 2rem;
    }
  }
  